<i18n>
ru:
  cart: Корзина
  checkout: Оформить заказ
  delete: Удалить товар
  menuLink: Перейти в меню
  numberInCart: 'Товаров в корзине: '
  hoverAmount: 'Количество:'
  hoverSum: 'Сумма заказа:'
  orderAmountTotal: 'Итого:'
  toCart: В корзину
ua:
  cart: Кошик
  checkout: Оформити замовлення
  delete: Видалити товар
  menuLink: Перейти до меню
  numberInCart: 'Товарів у кошику: '
  hoverAmount: 'Кількість:'
  hoverSum: 'Сума замовлення:'
  orderAmountTotal: 'Всього:'
  toCart: У кошик
us:
  cart: Cart
  checkout: Checkout
  delete: Delete item
  menuLink: Go to menu
  numberInCart: 'Items in the cart: '
  hoverAmount: 'Amount:'
  hoverSum: 'Order sum:'
  orderAmountTotal: 'Total:'
  toCart: In the cart
</i18n>

<template>
  <template v-if="loaded">
    <div class="v-small-basket-wrapper">
      <common-popover-menu
        class="v-small-basket"
        :disabled="!!isSmall"
        :show-on-click="false"
        placement="bottom-end"
        theme="small-cart"
      >
        <template #hoverable>
          <header-parts-basket-button-page @click="cartButtonClick" />
        </template>
        <template #content>
          <div class="v-small-basket-hover">
            <div class="v-d-flex v-justify-content-between v-mb-sm">
              <div class="v-small-basket-hover--title v-font-weight-600">
                <span
                  class="v-mr-xxs"
                  v-html="translate('smallBasket.numberInCart')"
                />
                <span
                  class="v-color-mono-400"
                  v-html="totalAmount"
                />
              </div>
              <div
                v-if="items.length > 0"
                class="v-pointer"
                @click="clearCart"
              >
                <icon-old-general-trashcan class="v-small-basket-hover--icon" />
              </div>
            </div>

            <div class="v-mb-md v-small-basket-hover--items">
              <div class="v-mb-sm">
                <cart-entwash-items-small />
              </div>
              <div
                v-if="appConfig.VueSettingsPreRun.CartExtraShowInSmallBasket"
                class="v-mb-sm"
              >
                <cart-entwash-extra-products :max-items-override="1.1" />
              </div>

              <cart-entwash-promocode
                v-if="items.length > 0"
                in-small-cart
              />
            </div>

            <div
              v-if="toPayWithMoney > 0 || toPayWithPoints > 0"
              class="v-d-flex v-justify-content-between v-align-items-baseline"
            >
              <div class="v-small-basket-hover--total v-align-currency-center">
                <span
                  class="v-mr-xxs"
                  v-html="translate('smallBasket.orderAmountTotal')"
                />
                <common-currency
                  v-if="toPayWithMoney === 0 && toPayWithPoints > 0"
                  class="v-font-weight-600"
                  show-points
                  :amount="toPayWithPoints"
                />
                <common-currency
                  v-else
                  class="v-font-weight-600"
                  :amount="toPayWithMoney"
                />
              </div>
              <arora-button
                class-name="v-small-basket-hover--button"
                :label="translate('smallBasket.toCart')"
                @click="toCart"
              />
            </div>
            <div
              v-else
              class="v-d-flex v-justify-content-end v-align-items-baseline v-py-xs"
            >
              <arora-button
                :label="translate('smallBasket.menuLink')"
                @click="navigateToMenu"
              />
            </div>
          </div>
        </template>
      </common-popover-menu>
    </div>
  </template>
  <common-skeleton v-else />
</template>

<script setup lang="ts">
import type { CartItem } from '~types/clientStore'

import { useWindowSize } from '@arora/common'

import { PositionType } from '~api/consts'

const { translate } = useI18nSanitized()

const { isSmall } = useWindowSize()
const { items } = useCart()
const restaurantStore = useRestaurantStore()
const clientStore = useClientStore()
const appConfig = useAppConfig()

onMounted(() => {
  clientStore.initClientState()
})

const panelOpen = ref<boolean>(false)

const loaded = computed<boolean>(() => {
  return clientStore.ClientState?.data !== null
})

const toPayWithMoney = computed<number>(
  () => clientStore.ClientState?.data?.Cart?.ToPayWithMoneyActive?.WithDiscount ?? 0
)

const toPayWithPoints = computed<number>(
  () => clientStore.ClientState?.data?.Cart?.ToPayWithPoints?.WithDiscount ?? 0
)

const content = computed<CartItem[]>(() => {
  const result: CartItem[] | undefined = clientStore.ClientState?.data?.Cart?.Content

  if (!result) return []

  return result.filter((ci: CartItem) => ci.PositionType !== PositionType.Additional)
})

const totalAmount = computed<number>(() => {
  if (content.value.length === 0) return 0

  return content.value
    .map((m) => m.Count)
    .reduce((item1, item2) => {
      return item1 + item2
    })
})

function clearCart(): void {
  clientStore.removeProductsFromCart(false)
}

function cartButtonClick(): void {
  if (isSmall.value) {
    navigateTo(appConfig.VueSettingsPreRun.Links.CartFirstStep)

    return
  }

  toCart()
}

function toCart(): void {
  navigateTo(appConfig.VueSettingsPreRun.Links.CartFirstStep)
}

function navigateToMenu(): void {
  navigateTo(appConfig.VueSettingsPreRun.Links.DefaultMenuGroup)
}

let once = true

watch(
  () => panelOpen.value,
  (newValue) => {
    if (newValue && once) {
      once = false

      restaurantStore.Metrics?.sendMetricCartView(true)
    }
  }
)
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/mixins';

.v-small-basket-wrapper {
  height: 100%;
  padding: 10px 0 0.5rem 0.5rem;
  width: fit-content;
  max-width: 100%;

  @include mixins.sm {
    padding: 0;
  }
}

.v-small-basket {
  will-change: opacity;
}

.v-small-basket-hover {
  padding: 28px;
  width: 440px;

  &--total {
    font-size: 1.25rem;
  }

  &--title {
    font-size: 1.1rem;
  }

  &--items {
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(125, 125, 125, 0.1);
  }

  &--button {
    width: 145px;
  }

  &--icon {
    @include mixins.trans;

    height: 24px;
    width: 24px;

    fill: none;

    path {
      stroke: variables.$Mono300;
    }

    &:hover {
      path {
        stroke: variables.$PrimaryBackgroundColor;
      }
    }
  }
}

.v-hover-show-promo {
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.v-slide-small-basket {
  background: variables.$Background;
  color: variables.$Mono1000;
  max-width: 440px;
  min-width: 440px;
  position: fixed;
  z-index: 1012;
  top: 0;
  right: 0;
  padding: 1rem 2rem 1rem 1rem;
  height: 100vh;
  box-shadow: variables.$FloatShadow;

  @include mixins.trans;

  overflow-y: auto;

  .v-slide-small-basket-top {
    padding: 1rem;
    margin-bottom: 1rem;

    .v-slide-small-basket-title {
      font-size: variables.$TextSizeH1;
    }

    .v-btn-border {
      border-radius: 50%;
      width: 30px;
      height: 30px;
      padding: 0;
      opacity: 0.7;
      display: flex;
      align-items: center;
      justify-content: center;
      color: variables.$Mono400;

      &:hover {
        opacity: 0.9;
      }
    }
  }

  .v-slide-small-basket-extra {
    padding: 10px 0 10px 15px;

    .v-extra-product-card {
      max-height: 130px;
      padding: 10px;
    }

    .shop-counter {
      max-height: 25px;
    }

    .v-extra-product-title {
      margin-bottom: 5px;
    }

    .v-extra-product-counter {
      margin-bottom: 5px;
    }
  }

  .v-slide-small-basket-bottom {
    padding: 15px;

    .promocode-container {
      max-width: 100%;
      width: 100%;
    }
  }
}

/* before the element is shown, start off the screen to the right */
.v-slide-enter-active,
.v-slide-leave-active {
  right: -100%;
}
</style>
